.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background: rgba(96, 125, 139, 0.67);
    animation: fade-in;
    animation-duration: 0.3s;
}

@keyframes fade-in {
    0% {
        opacity:0;
    }
    100% {
        opacity:1
    }
}

@keyframes fade-in-out {
    0% {
        opacity:0;
    }
    50% {
        opacity:1
    }
    100% {
        opacity:0
    }
}
.modal-footer button,
.modal-title button {
    background: transparent;
    border: 0;
    font-size:20px;
    height:45px;
    cursor:pointer;
    transition: color 0.3s;
}

.modal-footer button:hover, .modal-title button:hover {
    color: #FF5722;
}

.modal-window {
    background: white;
    width: 80vw;
    margin: 4rem auto;
    border-radius: 10px;
    display: grid;
    max-height: 90vh;
    grid-template-rows: auto auto auto 72px;
    animation: slide-down;
    animation-duration: 0.3s;
    box-shadow: 3px 3px 5px rgba(51, 51, 51, 0.38);
}

@keyframes slide-down {
    0% {
        margin-top:-50rem;
        opacity:0;
    }
    100% {
        margin-top:4rem;
        opacity:1
    }
}


.modal-title {
    display: grid;
    grid-template: "more close" auto/ 1fr 50px;
    padding: 1rem 1rem 1rem 4rem;
    border-bottom: 1px solid #eee;

}
.modal-body {
    display: grid;
    /* padding: 0; */
    border-bottom: 1px solid #eee;
    grid-template: "a a" /3fr 1fr;
    grid-gap: 20px;
    margin-right: 20px;
}
.modal-body img {
    margin: auto auto;
    max-width: 100%;
    max-height: 40vh;
}

.modal-footer {
    display: grid;
    grid-template: "link a more close" auto/ 1fr 1fr 1fr;
    padding: 1rem;
}

.modal-subtitle {
    padding: 0 3rem;
    border-bottom: 1px solid #eee;

}