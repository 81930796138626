.article img {
    /* https://alligator.io/css/cropping-images-object-fit/ */
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 3px 3px 0 0;
}

.article {
    grid-template: "img" minmax(150px, 250px) "b" auto "d" 30px;
    display: grid;
    border: 1px solid #333;
    padding: 15px;
    background: white;
    border-radius: 5px 5px 1px 1px;
}

.article a {
    margin-top:9px;
}

.article-menu {
    display: grid;
    grid-template: "a more" 1fr;
}

.article .more-info {
    grid-area: more;
    padding: 3px;
    border: none;
    background: #3F51B5;
    cursor: pointer;
    color: white;
}

.articleLink {
    grid-area:link;
}