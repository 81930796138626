/* nav.navigation {
    margin-top: 42px;
    flex:1;
} */
nav a {
    display: block;
    text-transform: capitalize;
}

nav.navigation ul {
    margin:0;
    padding:0;
}

nav.navigation ul li {
    flex-grow: 1;
    list-style-type: none;
    text-align: center;
    background: #1A237E;
}



nav.navigation ul li a {
    text-decoration: none;
    text-decoration: none;
    color: white;
    padding: 11px 0;
    transition: all 0.3s;
}

nav.navigation ul li a:after {
    content: " ";
    position: absolute;
    width: 10px;
    height: 2px;
    background: green;
    bottom: 5px;
    left: 0;
    width: 0%;
    margin-left: 5%;
    transition: all 0.5s;
}


nav.navigation ul li a.active {
    /* background: #1a257f; */
    position: relative;
}

nav.navigation ul li a.active:after {
    background: #673ab7;
    width: 90%;
}

nav.navigation ul li a.active:hover {
    color: #fff;
}


nav.navigation ul li:hover {
    background: #64B5F5!important;
}

nav.navigation ul li a:hover {
    color: #340f56;
}
nav svg.selected path {
    fill: red!important;
    stroke: #b92054!important;
    stroke-width: 31!important;
}

@media (min-width:801px) {
    nav.navigation ul {
        display: flex;
        padding: 0;
    }
    nav.navigation ul li:first-of-type a.active {
        border-top-left-radius: 10px;
    }
    
    nav.navigation ul li:last-of-type a.active {
        border-top-right-radius: 10px;
    }
    nav.navigation ul li:first-of-type {
        border-top-left-radius: 10px;
    }
    nav.navigation ul li:last-of-type {
        border-top-right-radius: 10px;
    }
    
}

@media (max-width:800px) {

    nav.navigation:hover {
        height: auto;
    }
    nav.navigation {
        height: 40px;
        overflow: hidden;
        position: absolute;
        right: 20px;
        z-index: 10;
    }
    nav.navigation ul li {
        border-radius: 0;
        padding: 0 10px;

    }
    nav.navigation ul li a.active {
        background: #1a257f;
        position: absolute;
        z-index: 20;
        top: 0;
        width: 100%;
        left: 0;
    }
    nav.navigation:hover a.active {
        background: initial;
        position: relative;
    }
    
}


